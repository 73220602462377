import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/liberato-mainella.jpeg";
  const fullName = `Liberato "Tony" Mainella`;
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = "December 2, 1938";
  const deathDate = "January 7, 2021";
  const memorialDate = "January 10-11, 2021";
  const memorialTime = "";
  const inviteText = ``;
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/LiberatoMainellaViewing-720.mp4",
      title: "Viewing",
    },
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/LiberatoMainellaServiceMass-1080.mp4",
      poster: "/images/people/tony-mainella-sun.png",
      title: "Service",
    },
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/LiberatoMainellaBurial-1080.mp4",
      poster: "/images/people/tony-mainella-mon.png",
      title: "Burial",
    },
  ];

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
    ></StoryPage>
  );
};

export default ThisPage;
